<template>
  <div class="page">
    <Navbar title="个人房产" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-cell-group>
        <van-field
          v-model="house.address"
          label="房产地址"
          placeholder="请输入房产地址"
          show-word-limit
          :rules="[{ required: true, message: '请输入房产地址' }]"
        />
        <van-field
          v-model="regionName"
          readonly
          clickable
          name="region"
          label="所在城市"
          placeholder="点击选择省市区"
          @click="showRegion = true"
        />
        <van-popup :show="showRegion" position="bottom">
          <van-area
            :area-list="regions"
            @confirm="confirmRegion"
            @cancel="state.showRegion = false"
          />
        </van-popup>
        <van-field
          v-model="house.area"
          label="房产面积(㎡)"
          placeholder="请输入房产面积"
          show-word-limit
          :rules="[{ required: true, message: '请输入房产面积' }]"
        />
        <van-field
          v-model="house.worth"
          label="房产价值(万)"
          placeholder="请输入房产价值"
          show-word-limit
          :rules="[{ required: true, message: '请输入房产价值' }]"
        />
      </van-cell-group>
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            icon="passed"
            size="small"
            color="#00CC99"
            class="btn"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Tag, Form, CellGroup, Field, Popup, Area } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Navbar: Navbar,
    Loading: Loading,
    [Tag.name]: Tag,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Area.name]: Area
  },

  data () {
    return {
      personCode: '',
      showRegion: false,
      seq: '',
      loadingShow: false,
      startDateShow: false,
      regions: { province_list: [], city_list: [], county_list: [] },
      regionName: '',
      house: { address: '', regionCode: '', area: '', worth: '' }
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.seq = query.seq
    this.retrieveHouse()
    this.retrieveRegions()
  },
  methods: {
    async retrieveHouse () {
      var pd = { personCode: this.personCode, key: 'PSN_HOS_ITEM', type: 'DTL', seq: this.seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        var content = JSON.parse(res.data.content)
        this.house.address = content.address
        this.house.regionCode = content.regionCode
        this.house.area = content.area
        this.house.worth = content.worth
        this.retrieveRegionName()
      }
    },
    confirmRegion (values) {
      this.regionName = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/')
      this.house.regionCode = values[2].code
      this.showRegion = false
    },
    async retrieveRegions () {
      var pd = { level: 3 }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/region/retrieveLevelRegion', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.regions.province_list = res.data.one
        this.regions.city_list = res.data.two
        this.regions.county_list = res.data.three
      }
    },
    async retrieveRegionName () {
      var pd = { code: this.house.regionCode }
      const { data: res } = await this.$http.post(this.UMS_URL + '/system/region/retrieveRegionName', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.regionName = res.data.one + '/' + res.data.two + '/' + res.data.three
      }
    },
    async submit () {
      var attrs = [
        { key: 'PSN_HOS_ITEM', content: JSON.stringify(this.house), type: 'DTL', seq: this.seq }
      ]
      var pd = { personCode: this.personCode, attrs: JSON.stringify(attrs) }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$router.go(-1)
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '编辑失败:' + res.msg
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 0px 5px;
  .btn {
    margin-top: 10px;
  }
}
</style>
